import React from "react";
import styled from "styled-components";
import ProductsGrid from "../components/ProductsGrid";
import RedBull from "../assets/redbull.png";
import CocaCola from "../assets/cocacola.png";
import Ferrero from "../assets/ferrerorocher.png";
import Nutella from "../assets/nutella.png";
import Milka from "../assets/milka.png";
import Raffaello from "../assets/raffaello.png";
import Fairy from "../assets/fairy.png";
import Finish from "../assets/finish.png";
import Twix from "../assets/twix.png";
import Nescafe from "../assets/nescafe.png";

const Section = styled.section`
  height: auto;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UpperBox = styled.div`
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 10vh;
  }
`;

const Title = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;

  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const SubTitle = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxl};
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;

  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.4em;
  }
`;

const ProductCards = () => {
  const products = [
    {
      id: 1,
      name: "Nutella 825g",
      image: Nutella,
      description:
        "Creamy and delicious chocolate and hazelnut spread, 825g jar for sweet moments.",
      barecode: "80051428",
      piecesPerCase: "6 pcs/cs",
      casesPerPalette: "128 cs/pal",
      piecesPerPalette: 768
    },
    {
      id: 2,
      name: "Coca Cola 330ml",
      image: CocaCola,
      description:
        "Classic carbonated soft drink, 330ml bottle for refreshing and energizing experience.",
      barecode: "5449000000996",
      piecesPerCase: "24 pcs/cs",
      casesPerPalette: "110 cs/pal",
      piecesPerPalette: 2640
    },
    {
      id: 3,
      name: "Ferrero Rocher T16 200g",
      image: Ferrero,
      description:
        "Premium chocolates, 16 pieces in 200g package, elegant and indulgent.",
      barecode: "8000500003787",
      piecesPerCase: "8 pcs/cs",
      casesPerPalette: "160 cs/pal",
      piecesPerPalette: 1280
    },
    {
      id: 4,
      name: "Red Bull 250ml",
      image: RedBull,
      description:
        "Popular energy drink, 250ml can for quick pick-me-up, unique and refreshing.",
      barecode: "9002490100070",
      piecesPerCase: "24 pcs/cs",
      casesPerPalette: "108 cs/pal",
      piecesPerPalette: 2592
    },
    {
      id: 5,
      name: "milka 100g",
      image: Milka,
      description:
        "Smooth and creamy chocolate, 100g package for sweet indulgence.",
      barecode: "3045140105502",
      piecesPerCase: "24 pcs/cs",
      casesPerPalette: "300 cs/pal",
      piecesPerPalette: 7200
    },
    {
      id: 6,
      name: "raffaello 150g",
      image: Raffaello,
      description:
        "Premium confectionery product, 150g bag for unique and delicious bite-sized treats.",
      barecode: "8000500023976",
      piecesPerCase: "6 pcs/cs",
      casesPerPalette: " 150 cs/pal",
      piecesPerPalette: "",
      piecesPerPalette: 900
    },
    {
      id: 7,
      name: " Fairy lemon 900ml",
      image: Fairy,
      description:
        "Powerful dishwashing liquid, 900ml bottle for tough grease and stains, fresh lemon scent.",
      barecode: "4015400957973",
      piecesPerCase: "12 pcs/cs",
      casesPerPalette: "60 cs/pal",
      piecesPerPalette: 720
    },
    {
      id: 8,
      name: "finish classic 10 capsules",
      image: Finish,
      description:
        "Powerful dishwasher detergent, 10 capsules for tough stains and brilliant shine.",
      barecode: "5011417565117",
      piecesPerCase: "16 pcs/cs",
      casesPerPalette: "70 cs/pal",
      piecesPerPalette: 1120
    },
    {
      id: 9,
      name: "twix 200g 4-pack",
      image: Twix,
      description:
        "Delicious chocolate bars, 4-pack of 200g bars, crunchy biscuit, creamy caramel, and milk chocolate.",
      barecode: "5000159384476",
      piecesPerCase: "18 pcs/cs",
      casesPerPalette: "130 cs/pal",
      piecesPerPalette: 2340
    },
    {
      id: 10,
      name: "nescafe gold 200g",
      image: Nescafe,
      description:
        "Premium instant coffee, carefully roasted for full-bodied flavor, 200g jar for convenience.",
      barecode: "7613036299459",
      piecesPerCase: "6 pcs/cs",
      casesPerPalette: "95 cs/pal",
      piecesPerPalette: 570
    },
  ];

  return (
    <Section>
      <UpperBox>
        <Title>Products</Title>
        <SubTitle>our Top-Sellers</SubTitle>
      </UpperBox>
      <ProductsGrid products={products} />
    </Section>
  );
};

export default ProductCards;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeroImage from "../assets/hero.jpg";
import TypeWritter from "../components/TypeWritter";

import Brands from "../sections/Brands";
import About from "../sections/About";
import Services from "../sections/Services";
import Offers from "../sections/Offers";




const Section = styled.div`
  min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
  background-color: ${(props) => props.theme.body};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    min-height: 100vh;
  }
`;

const Herobg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
  height: auto;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 35, 69);
    background: linear-gradient(
      90deg,
      rgba(0, 35, 69, 0.6265099789915967) 45%,
      rgba(0, 34, 68, 0.3688068977591037) 90%
    );
  }

  @media (max-width: 768px) {
    height: 100vh;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #232a34;
`;

const Container = styled.div`
  width: 75%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  @media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
  }
`;

const Box = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 2rem;
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontLargest};
  color: ${(props) => props.theme.body};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontxl};
    margin-bottom: 1rem;
  }
`;

const ContactBtn = styled.div`
  display: inline-block;
  padding: ${(props) => props.theme.actionButtonPadding};
  outline: none;
  border: none;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 600;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.body};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontmd};
    padding: 1rem 1.5rem;
  }
`;

const ContactInfo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 0.5rem 0;
  background-color: ${(props) => props.theme.primaryColor};

`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 2rem;
`;



const Email = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.body};
  transition: all 0.2s ease;

`;

const Address = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.body};
  transition: all 0.2s ease;

`;

const Phone = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.body};
  transition: all 0.2s ease;
`;
const Hero = () => {
  return (
    <>
      <Section id="home">
        <Herobg>
          <Image src={HeroImage} />
          <ContactInfo>
            <InfoItem>
              <Address href="http://maps.google.com/?q=Pozioma 4, 15-558 Białystok, Pologne" >Pozioma 4, 15-558 Białystok, Poland</Address>
            </InfoItem>
          </ContactInfo>
        </Herobg>
        <Container>
          <Box>
            <Title>your best fmcg partner</Title>
            <TypeWritter />
            <Link to="/contact">
              <ContactBtn>Contact Us Now</ContactBtn>
            </Link>
          </Box>
        </Container>
      </Section>
      <About />
      <Brands />
      <Services />
      <Offers />
      
    </>
  );
};
export default Hero;

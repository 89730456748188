import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ContactGlobal from "../assets/contactGlobal.svg";
import { Link } from "react-router-dom";

const CardContainer = styled.div`
  margin-bottom: 1rem;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  background-color: #fff;
  padding: 1rem 0;
  width: 15.5%;
  height: ${({ expanded }) => (expanded ? "auto" : "25em")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 10px rgb(0, 63, 125, 0.1);
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 5px 20px rgb(0, 63, 125, 0.2);
  }
  @media (max-width: 768px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;

const Image = styled.img`
  max-width: 90%;
  height: 300px;
  object-fit: scale-down;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const Content = styled.div`
  padding: 10px;
  align-self: start;
`;

const Name = styled.h3`
  font-size: 16px;
  text-transform: capitalize;
  margin: 0;
`;
const DescriptionContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const Description = styled.p`
  width: 100%;
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.primaryColor};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const InfoItem = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  align-items: flex-end;
  justify-content: flex-start;
  color: ${(props) => props.theme.secondaryColor};
  gap: 7px;
  cursor: pointer;
`;
const ContactIcon = styled.img`
  height: 25px;
  width: 25px;
  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
  }
`;

const ProductsGrip = ({ products, source }) => {
  const [expandedCards, setExpandedCards] = useState([]);
  const [productsArray, setProductsArray] = useState([]);

  const handleExpand = (productId) => {
    setExpandedCards((prevExpandedCards) =>
      prevExpandedCards.includes(productId)
        ? prevExpandedCards.filter((id) => id !== productId)
        : [...prevExpandedCards, productId]
    );
  };
  useEffect(() => {
    setProductsArray(products);
    console.log(productsArray.length);
  }, [productsArray]);

  console.log(productsArray);
  return (
    <CardContainer>
      {productsArray.map((productArray) => (
        <Card
          key={productArray.id}
          expanded={expandedCards.includes(productArray.id)}
        >
          <Image src={productArray.image} alt="image" />
          <Content>
            <Name>{productArray.name}</Name>
            <Button onClick={() => handleExpand(productArray.id)}>
              {expandedCards.includes(productArray.id)
                ? "Hide Details"
                : "Show Details"}
            </Button>
            {expandedCards.includes(productArray.id) && (
              <DescriptionContainer>
                {source === "offersProducts" ? (
                  <ContactInfo>
                    <Description>{productArray.description}</Description>
                    <Link to="/contact">
                    <InfoItem>
                      <ContactIcon src={ContactGlobal}></ContactIcon> Contact
                    </InfoItem>
                    </Link>
                  </ContactInfo>
                ) : (
                  <ContactInfo>
                    <Description>EAN: {productArray.barecode}</Description>
                    <Description>
                      Ps/cs: {productArray.piecesPerCase}
                    </Description>
                    <Description>
                      Cs/pal: {productArray.casesPerPalette}
                    </Description>
                    <Description>
                      Ps/pal: {productArray.piecesPerPalette}
                    </Description>
                    <br />
                    <Description>{productArray.description}</Description>
                  </ContactInfo>
                )}
              </DescriptionContainer>
            )}
          </Content>
        </Card>
      ))}
    </CardContainer>
  );
};

export default ProductsGrip;

import React from "react";
import styled from "styled-components";
import AboutImage from "../assets/hero.jpg";
import Price from "../assets/price.png";
import Doc from "../assets/doc.png";
import Support from "../assets/support.png";

const Section = styled.section`
  min-height: 85vh; 
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UpperBox = styled.div`
  padding: 2.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryColor};
`;
const Title = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.body};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const MidBox = styled.div`
  margin-top: 1em;
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media (max-width: 768px) {
    width: 95%;
    height: auto;
    flex-direction: column;
  }
`;
const Box = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

   @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 85%;
      object-fit: contain;
    }
  }
`;

const BoxTitle = styled.h2`
  text-align: start;
  font-size: 3em;
  font-weight: 900;
  color: #003f7d;
  text-transform: capitalize;
  align-self: start;
  @media (max-width: 768px) {
    font-size: 2em;
    font-weight: 900;
  }
`;
const BoxText = styled.h2`
  margin-top: 1rem;
  width: 80%;
  font-size: 1.25em;
  font-weight: 500;
  color: #202020;
  text-align: start;
  align-self: start;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const LowerBox = styled.div`
  margin: 1em 0;
  width: 75%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const LowerBoxTitle = styled.h2`
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.secondaryColor};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const ValuesContainer = styled.div`
  margin-top: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ValueCart = styled.div`
  height: 80%;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const CartHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 600;
  img {
    height: 64px;
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    height: auto;
    font-size: ${(props) => props.theme.fontmd};
    img {
      height: 45px;
      margin-right: 15px;
    }
  }
`;
const CartText = styled.h2`
  margin-top: 1rem;
  width: 100%;
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.text};
  text-align: start;
  align-self: start;
  @media (max-width: 768px) {
    flex-direction: row;
    height: auto;
    font-size: ${(props) => props.theme.fontsm};
  }
`;
const About = () => {
  return (
    <Section id="about">
      <UpperBox>
        <Title>Our Company</Title>
      </UpperBox>
      <MidBox>
        <Box>
          <BoxTitle>How we work</BoxTitle>
          <BoxText>
            FMCG Moment are global traders working in FMCG area. To save our
            clients time and to offer competitive prices we are working based on
            triangle trade rules – it means we sell directly from suppliers
            warehouses. We work with market-leading global, strong local and
            promising new FMCG brands. We take care of sales, marketing,
            logistics, product placement and promo services, we offer coverage
            of the whole territory through various sales channels.
          </BoxText>
        </Box>
        <Box>
          <img src={AboutImage} alt=""/>
        </Box>
      </MidBox>
      <LowerBox>
        <LowerBoxTitle>why us</LowerBoxTitle>
        <ValuesContainer>
          <ValueCart>
            <CartHead>
              <img src={Price} alt=""/>
              Best Prices
            </CartHead>
            <CartText>
              {" "}
              We search in depth to provide our clients the very best
              competitive prices
            </CartText>
          </ValueCart>
          <ValueCart>
            <CartHead>
              <img src={Support} alt=""/>
              support
            </CartHead>
            <CartText>
              {" "}
              Our support team is 24/7 available for our clients during the
              whole service
            </CartText>
          </ValueCart>
          <ValueCart>
            <CartHead>
              <img src={Doc} alt=""/>
              Documentation
            </CartHead>
            <CartText>
              {" "}
              We prepare all the needed documents to export goods to our clients
            </CartText>
          </ValueCart>
        </ValuesContainer>
      </LowerBox>
    </Section>
  );
};

export default About;

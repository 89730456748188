import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "./Logo";

const Section = styled.section`
  width: 100vw;
  background-color: ${(props) => props.theme.body};
`;

const Navigation = styled.nav`
  width: 85%;
  height: ${(props) => props.theme.navHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;

  @media (max-width: 768px) {
    position: absolute;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    width: 100%;
    height: calc(100vh - ${(props) => props.theme.navHeight});
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    background-color: ${(props) => props.theme.body};
    transform: ${(props) =>
      props.show ? "translateY(0px)" : "translateY(-150%)"};
    transition: transform 0.3s ease-in-out;
    z-index: 2; 
  }
`;

const MenuItem = styled.li`
  margin: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    border-radius: 2px;
    transition: width 0.3s ease;
    background-color: ${(props) => props.theme.text};
  }
  &:hover::after {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;
const HamburgerMenuContainer = styled.div`
height: 1.5rem;
width:  1.5rem;
padding: 2px;
`;

const HamburgerMenu = styled.span`
  width: 1.5rem;
  height: 2px;
  background-color: ${(props) => props.theme.text};
  position: absolute;
  top: 2.5rem;
  right: 0;
  transform: translateX(-100%);

  display: none;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  cursor: pointer;

  @media (max-width: 768px) {
    top: 2.5rem;
    display: flex;
  }

  &::after,
  &::before {
    content: "  ";
    width: 1.5rem;
    height: 2px;
    background-color: ${(props) => props.theme.text};
    position: absolute;
    transition: transform 0.3s ease;
  }

  &::after {
    top: 0.5rem;
  }

  &::before {
    bottom: 0.5rem;
  }

  &.active {
    transform: translateX(-100%) rotate(180deg);
    height: 0px;
    &::after {
      transform: translateY(-0.65rem) rotate(-45deg);
    }
    &::before {
      transform: translateY(0.5rem) rotate(45deg);
    }
  }
`;

const NavBar = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleMenuItemClick = () => {
    setIsActive(false);
  };

  return (
    <Section>
      <Navigation>
        <Logo />
        <HamburgerMenuContainer onClick={handleClick}>
          <HamburgerMenu
            className={isActive ? "active" : ""}
          />
        </HamburgerMenuContainer>
        <Menu show={isActive}>
          <MenuItem>
            <Link to="/" onClick={handleMenuItemClick}>
              Home
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/about" onClick={handleMenuItemClick}>
              About
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/services" onClick={handleMenuItemClick}>
              Services
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/offers" onClick={handleMenuItemClick}>
              Offers
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/contact" onClick={handleMenuItemClick}>
              Contact
            </Link>
          </MenuItem>
        </Menu>
      </Navigation>
    </Section>
  );
};

export default NavBar;

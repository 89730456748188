export const light = {
  //colors
  body: "#fffcf8",
  text: "#202020",
  bodyRgba: "255, 252, 248",
  textRgba: "32, 32, 32",
  secondaryColor: "#003F7D", //blue
  primaryColor: "#FF5003", //orange

  //fonts
  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em", //16px
  fontlg: "1.25em",
  fontxl: "1.8em",
  fontxxl: "2em",
  fontxxxl: "2.5em",
  fontLargest: "4em",

  //mesures
  navHeight: "5rem",
  actionButtonPadding: "0.8rem 2.5rem",
};

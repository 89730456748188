import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProductsGrid from "../components/ProductsGrid";

const Section = styled.section`
  min-height: 80vh;
  width: 100vw;
`;

const UpperBox = styled.div`
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.body};
  @media (max-width: 768px) {
    height: 10vh;
  }
`;

const Title = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const SubTitle = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxl};
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.4em;
  }
`;
const LowerBox = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: auto;
    margin-bottom: 1rem;
  }
`;


const CoffeeAndTea = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const imageFiles = importAll(
      require.context("../assets/Coffee", false, /\.(png|jpe?g|svg)$/)
    );
      
    const products = imageFiles.map(
      (imageFile, index) => ({
      id: index,
      name: imageFile.match(/\/([^/]+)$/)[1].replace(/\.[^.]*\./g, '.').replace(/\.[^.]+$/, ""),
      image: imageFile,
      description: "For more details contact the seller",
     
    })
    );
    imageFiles.forEach(element => {
      console.log(element)
    });
    setProducts(products);
    

  }, []);
  
  return (
    <Section>
      <UpperBox>
        <Title>Our Offers</Title>
        <SubTitle>Coffee and Tea</SubTitle>
      </UpperBox>
      <LowerBox>
      <ProductsGrid products={products} source="offersProducts"/>
      </LowerBox>
    </Section>
  );
};

export default CoffeeAndTea;

import React from "react";
import styled from "styled-components";
import ServicesImage from "../assets/services.jpg";

const Section = styled.section`

  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UpperBox = styled.div`
  padding: 2.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryColor};
`;
const Title = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.body};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const LowerBox = styled.div`
  height: 85vh;
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 95%;
    flex-direction: column;
  }
`;
const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;


const ImageBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    width: 50%;
    height: 85vh;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
  background-color: #232a34;
`;

const BoxTitle = styled.h2`
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.secondaryColor};
  text-transform: capitalize;
  align-self: start;
  @media (max-width: 768px) {
    font-size: 2em;
    font-weight: 900;
    }
`;
const BoxText = styled.h2`
  margin-top: 1rem;
  width: 80%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 500;
  color: ${(props) => props.theme.text};
  text-align: start;
  align-self: start;
  @media (max-width: 768px) {
   width: 100%;
    }
`;

const Pricing = styled.h4`
  padding: 0.1rem 0.5rem;
  margin-top: 1rem;
  width: 80%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 500;
  color: ${(props) => props.theme.body};
  background-color: ${(props) => props.theme.secondaryColor};
  text-align: start;
  align-self: start;
`;
const WarehouseSolution = styled.h4`
  padding: 0.1rem 0.5rem;
  margin-top: 1rem;
  width: 70%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 500;
  color: ${(props) => props.theme.body};
  background-color: ${(props) => props.theme.secondaryColor};
  text-align: start;
  align-self: start;
`;
const ServiceQuality = styled.h4`
  padding: 0.1rem 0.5rem;
  margin-top: 1rem;
  width: 75%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 500;
  color: ${(props) => props.theme.body};
  background-color: ${(props) => props.theme.secondaryColor};
  text-align: start;
  align-self: start;
`;

const Services = () => {
  return (
    <Section id="services">
      <UpperBox>
        <Title>Our Services</Title>
      </UpperBox>
      <LowerBox>
        <Box>
          <BoxTitle>Service overview</BoxTitle>
          <BoxText>
            FMCG Moment is an international trade company of top brands, we both
            purchasing and selling, offering possibility of building European
            distribution for your brand or producing a product on your demand.
          </BoxText>
          <Pricing>Pricing</Pricing>
          <WarehouseSolution>Warehouse Solutions</WarehouseSolution>
          <ServiceQuality>Service Quality</ServiceQuality>
        </Box>
        <ImageBox>
          <Image src={ServicesImage} />
        </ImageBox>
      </LowerBox>
    </Section>
  );
};

export default Services;

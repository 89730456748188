import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import fmcgLogo from "../assets/fmcgMomentLogo.png";

const LogoImg = styled.img`
  height: 4rem;
  transform: translateY(10px);
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    
  }
`
const Logo = () => {
  return (

        <Link   to={"/"}>
       <LogoImg src={fmcgLogo} alt="Company Logo" />
        </Link>

  )
}

export default Logo
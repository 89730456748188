
import React from 'react';
import styled from 'styled-components';


const MapContainer = styled.div`
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  @media (min-width: 768px) {
    height: 500px;
    
  }
`;

const GMap = () => {
  return (
    <MapContainer>
      <iframe
        title="My Current Location"
        src={`https://maps.google.com/maps?q=${53.117183519985026},${23.220221450698773}&output=embed`}
      ></iframe>
    </MapContainer>
  );
};

export default GMap;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SweetImg from "../assets/sweets.jpeg";
import BeverageImg from "../assets/drinks.jpeg";
import ChemicalsImg from "../assets/detergent.jpeg";
import CoffeeImg from "../assets/coffee.jpeg";
import SkinCareImg from "../assets/skincare.jpeg";
import ProductCards from "./ProductCards";

const offersData = [
  {
    id: 1,
    name: "Sweets",
    link: "/offers/sweets",
    image: SweetImg,
    description:
      "Everyone loves sweets, and they play an important role in our lives. At our store, we offer a wide range of delicious treats, including chocolates, candy bars, cookies, jellies, and premium chocolate brands. Some of our popular products include Rafaello, Ferrero, Storck, Lindt, Mars, Ritter Sport, Schogetten, Haribo, Oreo, Kinder, and Nutella. We're committed to offering high-quality and diverse products that meet the needs of all our customers, even those with the most discerning tastes. If you're interested in learning more about our selection, please don't hesitate to contact us and check out our catalog.",
  },
  {
    id: 2,
    name: "Drinks and beverages",
    link: "/offers/bevrages",
    image: BeverageImg,
    description:
      "We offer a diverse range of refreshing beverages, featuring top-tier brands including Evian, Perrier, Coca-Cola, Monster, Sprite, Fanta, Pepsi, Dr Pepper, and many others. Our commitment to providing competitive prices and exceptional quality is unwavering. To learn more about our extensive selection, we encourage you to peruse our catalog or get in touch with us directly.",
  },
  {
    id: 3,
    name: "Coffee & Tea",
    link: "/offers/coffee and tea",
    image: CoffeeImg,
    description:
      "Hot beverages such as coffee and tea are widely enjoyed by many individuals. Our store offers premium quality products from renowned brands. Our extensive selection includes Lavazza, Nescafe, Jacobs, Lipton, Illy, and many others. To learn more about our diverse range of products, please feel free to get in touch with us directly.",
  },
  {
    id: 4,
    name: "Skin & Body Care",
    link: "/offers/skincare",
    image: SkinCareImg,
    description:"we believe that healthy and beautiful skin is key to feeling confident and radiant. That's why we've carefully curated a selection of premium skincare products designed to nourish and enhance your natural beauty. From gentle cleansers to luxurious moisturizers, our range includes everything you need to achieve a healthy and glowing complexion. Our products are made with high-quality ingredients and are free from harmful chemicals, ensuring that your skin is well-cared for. We're passionate about helping our customers feel their best, and we're committed to providing affordable prices without compromising on quality. All of which have been carefully formulated to meet the unique needs of your skin. To learn more about our extensive selection and find the perfect products for your skin type, please feel free to contact us directly." },
  {
    id: 5,
    name: "Household Chemicals",
    link: "/offers/household chemicals",
    image: ChemicalsImg,
    description:
      "Essential household items are crucial in maintaining cleanliness and organization in our homes. At our store, we offer a wide range of domestic detergents, including washing powders, capsules, liquids, dishwashing liquids, dishwasher tablets, and more. Our inventory features reputable brands such as Fairy, Persil, Ariel, FINISH, CIF, AJAX, and Pampers. We strive to provide our customers with top-quality products to meet their everyday cleaning needs. Please don't hesitate to contact us if you're interested in learning more about our selection of domestic detergents.",
  },
];
const Section = styled.section`
  min-height: 80vh;
  width: 100vw;
`;

const UpperBox = styled.div`
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.body};
  @media (max-width: 768px) {
    height: 10vh;
  }
`;

const Title = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const SubTitle = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxl};
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.4em;
  }
`;
const LowerBox = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: auto;
    margin-bottom: 1rem;
  }
`;

const Grid = styled.div`
  width: 75%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 75%;
  }
`;

const Card = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 10px rgb(0, 63, 125, 0.1);
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 5px 20px rgb(0, 63, 125, 0.2);
  }
  @media (max-width: 768px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  
`;

const CategoryName = styled.h3`
  text-align: center;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 700;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContent = styled.div`
  width: 60%;
  height: auto;
  background: ${(props) => props.theme.body};
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 768px) {
    width: 70%;
    height: 85%;
    padding: O;
    flex-direction: column;
  }
`;

const PopupCloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  background: transparent;
  font-size: 1.5rem;
  outline: none;
  border: none;
  font-weight: 500;
  line-height: 1rem;
  cursor: pointer;
`;

const PopupImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100%;
    height: 150px;
  }
`;

const PopupRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  aling-items: flex-start;
  justify-content: center;
`;
const PopupTitle = styled.h1`
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;
const PopupDescription = styled.p`
  text-align: start;
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const ContactButton = styled.button`
  display: inline-block;
  padding: ${(props) => props.theme.actionButtonPadding};
  outline: none;
  border: none;
  font-size: ${(props) => props.theme.fontsm};
  font-weight: 600;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.body};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: ${(props) => props.theme.fontsm};
    align-self: flex-end;
  }
`;

const Offers = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCardClick = (category) => {
    setSelectedCategory(category);
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setSelectedCategory(null);
  };

  return (
    <Section>
      <UpperBox>
        <Title>Our Offers</Title>
        <SubTitle>The products we offer include</SubTitle>
      </UpperBox>
      <LowerBox>
        <Grid>
          {offersData.map((offer) => (
            <Link to={offer.link}>
            <Card key={offer.id} onClick={() => handleCardClick(offer)}>
              <Image src={offer.image} alt={offer.name} />
              <CategoryName>{offer.name}</CategoryName>
            </Card>
            </Link>
          ))}
        </Grid>
        {showPopup && selectedCategory && (
          <Popup onClick={handlePopupClose}>
            <PopupContent onClick={(e) => e.stopPropagation()}>
              <PopupImage
                src={selectedCategory.image}
                alt={selectedCategory.name}
              />
              <PopupRight>
                <PopupTitle>{selectedCategory.name}</PopupTitle>
                <PopupDescription>
                  {selectedCategory.description}
                </PopupDescription>
                <Link to="/contact">
                  <ContactButton>Contact Seller</ContactButton>
                </Link>
              </PopupRight>
              <PopupCloseButton onClick={handlePopupClose}>X</PopupCloseButton>
            </PopupContent>
          </Popup>
        )}
      </LowerBox>
      <ProductCards />
    </Section>
  );
};

export default Offers;

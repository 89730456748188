/* eslint-disable */
const { createGlobalStyle } = require("styled-components");
import "@fontsource/ubuntu";


const GlobalStyles = createGlobalStyle`
*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body {
    font-family: "Ubuntu", sans-serif;
    overflow-x: hidden;
    background-color: #fffcf8;
}
h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration: none;
}
`
export default GlobalStyles;
import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import "@fontsource/akaya-telivigala";

const TypeWritterText = styled.h2`
  margin-bottom: 2rem;
  font-size: ${props => props.theme.fontLargest};
  font-weight: 900;
  text-transform: capitalize;
  color: ${props => props.theme.primaryColor};
  align-self: flex-start;

  @media (max-width: 64em) {
    font-size: ${props => props.theme.fontxl};
   
  }
`;
const TypeWritter = () => {
  return (
    <TypeWritterText>
      <Typewriter
        options={{ autoStart: true }}
        onInit={(typewriter) => {
          typewriter
            .typeString("The Best Products")
            .pauseFor(500)
            .deleteAll()
            .typeString('At The Best Deals!')
            .pauseFor(500)
            .start();
        }}
      />
    </TypeWritterText>
  );
};

export default TypeWritter;

import React from "react";
import styled from "styled-components";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import fmcgLogo from "../assets/fmcgMomentLogo.png";

const Section = styled.div`
  background-color: #97b6e4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
`;
const FooterContent = styled.footer`
  background-color: #97b6e4;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 25%;
  padding: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
`;
const Logo = styled.img`
  height: 7rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
  }
`;
const CompanyText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.secondaryColor};
  @media (max-width: 768px) {
    width: 80%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 25%;
  padding: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
`;
const CategoryLink = styled.a`
  color: ${(props) => props.theme.secondaryColor};
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;
  margin-bottom: 1rem;
  text-decoration: none;
`;
const FooterTitle = styled.a`
  color: ${(props) => props.theme.secondaryColor};
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 900;
  text-align: center;
  margin-bottom: 1rem;
  text-decoration: none;
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 25%;
  padding: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
`;
const ContactInfo = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.secondaryColor};
  text-align: start;
  margin-bottom: 1rem;
`;
const SocialMediaContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  width: 50%;
`;
const SotcialMediaLins = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const SocialMediaLink = styled.a`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.secondaryColor};
  transform: scale(1.4);
  transition: all 0.5s ease;
  &:hover {
    color: ${(props) => props.theme.primaryColor};
    transform: scale(1.6);
  }
`;

const SocialMediaIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterText = styled.p`
  color: ${(props) => props.theme.secondaryColor};
  font-size: ${(props) => props.theme.fontsm};
  margin: 1rem;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const FooterLink = styled.a`
  color: ${(props) => props.theme.secondaryColor};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <Section>
      <FooterContent>
        <LeftDiv>
          <Logo src={fmcgLogo} alt="Company Logo" />
          <CompanyText>
            is a multinational corporation that specializes in the distribution,
            export, and import of fast-moving consumer goods (FMCG) within the
            Europe-Asia region. Their portfolio comprises a wide range of
            top-quality products from renowned global brands, including but not
            limited to Coca Cola, Ferrero, Mondelez, Mars, Nestle, P&G,
            Unilever, Heineken, Budweiser, and others.
          </CompanyText>
        </LeftDiv>
        <CenterDiv>
          <FooterTitle>Offers</FooterTitle>
          <CategoryLink href="/offers/sweets">Sweets</CategoryLink>
          <CategoryLink href="/offers/bevrages">
            Drinks & beverages
          </CategoryLink>
          <CategoryLink href="/offers/coffee and tea">
            Coffee & Tee
          </CategoryLink>
          <CategoryLink href="/offers/skincare">Skin & Body Care</CategoryLink>
          <CategoryLink href="/offers/household chemicals">
            Household Chemicals
          </CategoryLink>
        </CenterDiv>
        <RightDiv>
          <FooterTitle>Contact Us</FooterTitle>
          <ContactInfo>contact@fmcgmoment.pl</ContactInfo>
          <ContactInfo>+48 508 788 359</ContactInfo>
          <SocialMediaContainer>
            <FooterTitle>Find Us</FooterTitle>
            <SotcialMediaLins>
              <SocialMediaLink href="#">
                <SocialMediaIcon>
                  <FaFacebook />
                </SocialMediaIcon>
              </SocialMediaLink>
              <SocialMediaLink href="#">
                <SocialMediaIcon>
                  <FaLinkedin />
                </SocialMediaIcon>
              </SocialMediaLink>
              <SocialMediaLink href="#">
                <SocialMediaIcon>
                  <FaInstagram />
                </SocialMediaIcon>
              </SocialMediaLink>
            </SotcialMediaLins>
          </SocialMediaContainer>
        </RightDiv>
      </FooterContent>
      <FooterText>
        © 2023 FMCGMoment. All rights reserved. |{" "}
        <FooterLink href="">Privacy Policy</FooterLink> |{" "}
        <FooterLink href="">Terms of Use</FooterLink>
      </FooterText>
    </Section>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Section = styled.section`
  min-height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.body};

`;

const UpperBox = styled.div`
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.body};

  @media (max-width: 768px) {
    height: 10vh;
  }
`;

const Title = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;

  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const SubTitle = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxl};
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
  text-transform: capitalize;

  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.4em;
  }
`;

const LowerBox = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const GridContainer = styled.div`
  width: 75%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    width: 90%;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridItem = styled.img`
  width: 100%;
  display: flex;
  object-fit: cover;
  
`;

const Brands = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const imageFiles = importAll(
      require.context("../assets/Brands", false, /\.(png|jpe?g|svg)$/)
    );

    const brandsImages = imageFiles.map((imageFile, index) => ({
      image: imageFile,
      id: index,
    }));

    setBrands(brandsImages);
  }, []);

  return (
    <Section id="brands">
      <UpperBox>
        <Title>Brands</Title>
        <SubTitle>our world-class brands</SubTitle>
      </UpperBox>
      <LowerBox>
        <GridContainer>
          {brands.map((brand) => (
            <GridItem src={brand.image} />
          ))}
        </GridContainer>
        
      </LowerBox>
    </Section>
  );
};

export default Brands;

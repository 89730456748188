import GlobalStyles from "./styles/GlobalStyles";
import { Routes, Route } from 'react-router-dom';
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import Footer from "./components/Footer";
import NavBar from "./components/Navbar";
import About from "./sections/About";
import Home from "./sections/Home";
import Services from "./sections/Services";
import Offers from "./sections/Offers";
import Contact from "./sections/Contact";
import Bevrages from "./sections/Bevrages";
import CoffeAndTea from "./sections/CoffeeAndTea";
import Sweets from "./sections/Sweets";
import Skincare from "./sections/Skincare";
import HouseholdChemicals from "./sections/HouseholdChemicals";

function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/offers/sweets" element={<Sweets />} />
          <Route path="/offers/bevrages" element={<Bevrages />} />
          <Route path="/offers/coffee and tea" element={<CoffeAndTea />} />
          <Route path="/offers/skincare" element={<Skincare />} />
          <Route path="/offers/household chemicals" element={<HouseholdChemicals />} />
       </Routes>
        <Footer/>  
      </ThemeProvider>
    </>
  );
}

export default App;
